<template>
  <v-card
      class="align-end"
      outlined
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div v-if="player.usid" class="text-overline">
          {{ player.usid }}
        </div>
        <div v-else class="text-overline">
          нет в sewa
        </div>
        <v-list-item-title class="text-h6 mb-4">
          {{ player.name }}
        </v-list-item-title>
        <v-list-item-subtitle style="opacity: 0.5">

          <v-chip class="mr-1" x-small label color="primary">
            <v-icon left x-small>mdi-label</v-icon>
            small chip
          </v-chip>

          <v-chip class="mr-1" x-small label color="primary">
            <v-icon left x-small>mdi-label</v-icon>
            small chip
          </v-chip>

          <v-chip class="mr-1" x-small label color="primary">
            <v-icon left x-small>mdi-label</v-icon>
            small chip
          </v-chip>

          <v-chip class="mr-1" x-small label color="primary">
            <v-icon left x-small>mdi-label</v-icon>
            small chip
          </v-chip>

        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar
          style="opacity: 0.05"
          tile
          size="80"
      >
        <v-img :src="get_img()"></v-img>
      </v-list-item-avatar>
    </v-list-item>
    <v-divider dark></v-divider>
    <v-card-actions>

      <div style="width: 100%; line-height: 0.7;">
        <v-progress-linear
            height="10"
            class="mr-3 mt-1 opacity"
            background-color="tertiary"
            color="primary"
            :value="get_karma_value()"
            rounded
            @click="$emit('formKarma', player)"
        ></v-progress-linear>
        <!--        <span style="font-size: xx-small; color: #cfcfcf"> {{ params.karma_list[player.karma.textID][1] }} </span>-->
        <v-btn
            @click="form_karma_log = true"
            x-small
            text
            color="grey accent-4"
            class="mt-1"
        >
          {{ params.karma_list[player.karma.textID][1] }}
        </v-btn>
      </div>
      <v-spacer>
        <help text="Карма и рейтинг. Карму меняют вам игроки,<br/>рейтинг растет в зависимости от вашей активности"
              button/>
      </v-spacer>
      <div style="line-height: 0.1">
        <v-rating
            class="ml-3"
            readonly
            background-color="grey lighten-1"
            color="yellow accent-4"
            dense
            half-increments
            hover
            size="18"
            :value=player.rating.stars
        ></v-rating>
        <v-btn disabled x-small text color="teal accent-4">
          {{ get_rank() }}
        </v-btn>
        <!--        <span style="font-size: xx-small; color: #cfcfcf"></span>-->
      </div>
    </v-card-actions>

    <karma_log :open.sync="form_karma_log" :player="player" />


  </v-card>
</template>


<script>

export default {
  components: {
    "help": () => import('@/site/componets/help'),
    "karma_log": () => import('./player-karma_log'),
  },
  props: {
    player: Object,
    params: Object
  },
  data() {
    return {
      form_karma_log: false,
      // name: this.card.name,
      // location: this.card.location,
      // rating: this.card.rating,
      // link: this.card.link,
      // photo: this.getPhotoUrl()
    }
  },
  methods: {
    get_img() {
      return require('@/assets/avatar2.svg')
      // if (this.product.type === 'PRD') {
      //   return require('@/views/shop/assets/5.svg')
      // } else {
      //   return require('@/views/shop/assets/clock.svg')
      // }
    },
    get_rank() {
      let rank = this.params.rank_list.find(obj => obj[0] === this.player.rating.textID)
      return rank ? rank[1] : ''
    },
    get_karma_value() {
      return this.$lib.mapping(this.player.karma.score, this.params.karma_interval)
    }
  },
}
</script>

<style scoped>

.opacity {
  opacity: 0.2
}

.opacity:hover {
  opacity: 0.4;
  cursor: pointer;
}

</style>